import styled from "styled-components";
import backgroundImage from "../../assets/images/background.png";

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  z-index: 0;
`;

export const Text = styled.h1`
  position: absolute;
  font-family: Ubuntu;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  z-index: 1;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 25px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 25px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const TabletText = styled.h1`
  position: absolute;
  font-family: Ubuntu;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  z-index: 1;
  display: none;

  @media (max-width: 1024px) {
    font-size: 32px;
    display: inline;
  }

  @media (max-width: 820px) {
    font-size: 26px;
    display: inline;
  }

  @media (max-width: 431px) {
    display: none;
  }
`;

export const PhoneText = styled.h1`
  position: absolute;
  font-family: Ubuntu;
  font-size: 20.12px;
  font-weight: 400;
  color: #ffffff;
  z-index: 1;
  display: none;

  @media (max-width: 431px) {
    display: inline;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    font-size: 15px;
  }
`;

export const Indent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Bold = styled.b`
  color: #3be65a;
`;
