import React from "react";
import * as C from "./Description.styles";

const Description = ({ scrollToAbout }) => {
  const handleScrollToAbout = () => {
    if (scrollToAbout && scrollToAbout.current) {
      scrollToAbout.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <C.Container>
      <C.Text>
        Com a Mercadados, você terá acesso a <C.Bold>dados de</C.Bold>
        <br />
        <C.Bold>mercado precisos e análises práticas</C.Bold> para
        <br />
        tomar decisões assertivas e conquistar
        <br />
        novas oportunidades.
      </C.Text>
      <C.TextPhone>
        Com a Mercadados, você terá<br/>
        acesso a <C.Bold>dados de mercado</C.Bold><br/>
        <C.Bold>precisos e relevantes</C.Bold> para tomar<br/>
        decisões assertivas e conquistar<br/>
        novas oportunidades.
      </C.TextPhone>
      <C.Button onClick={handleScrollToAbout}>
        <C.ButtonTxt>Sobre a empresa</C.ButtonTxt>
      </C.Button>
    </C.Container>
  );
};

export default Description;
