import React from "react";
import * as C from "./Contact.styles";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linkedin.png";
import email from "../../assets/images/email.png";
import image from "../../assets/images/hub.png";

const Contact = React.forwardRef((props, ref) => {
  return (
    <C.Container ref={ref}>
      <C.Title>CONTATO</C.Title>
      <C.All>
        <C.Social>
          <C.Indent>
            <C.Icon src={instagram} />
            <C.Text href="https://www.instagram.com/mercadados_oficial?igsh=dnpiNDhyZTV2cTZm">
              Instagram
            </C.Text>
          </C.Indent>
          <C.Indent>
            <C.Icon src={linkedin} />
            <C.Text href="https://www.linkedin.com/company/mercadados/">
              Linkedin
            </C.Text>
          </C.Indent>
          <C.Indent>
            <C.Icon src={email} />
            <C.Text href="mailto:mercadados@mercadados.com">E-mail</C.Text>
          </C.Indent>
        </C.Social>
        <C.Location>
          <C.TextLocation>
            Av. 7 de Setembro, 496 - Sala 305
            <br />
            Centro, Passo Fundo - RS, 99010-120
          </C.TextLocation>
          <C.Image src={image} />
        </C.Location>
      </C.All>
    </C.Container>
  );
});

export default Contact;
