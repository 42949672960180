import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  margin-block: 4rem;
  margin-inline: 6.2rem;

  @media (min-width: 820px) {
    margin-inline: 4rem;
  }

  @media (max-width: 431px) {
    margin-inline: 2rem;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  @media (min-width: 1440px) and (max-width: 1660px) {
    width: 1070px;
    height: 521px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 970px;
    height: 421px;
  }

  @media (max-width: 1024px) {
    width: 528.24px;
    height: 618px;
  }

  @media (max-width: 820px) {
    width: 408.24px;
    height: 498px;
    position: absolute;
    right: 4rem;
  }

  @media (max-width: 768px) {
    width: 408.24px;
    height: 498px;
    right: 5.5rem;
  }

  @media (max-width: 431px) {
    width: 296px;
    height: 346.3px;
    top: 104px;
    left: 105px;
  }

  @media (max-width: 412px) {
    left: 90px;
  }

  @media (max-width: 360px) {
    width: 256px;
    height: 306.3px;
    left: 80px;
    top: 90px;
  }

  @media (max-width: 391px) {
    left: 70px;
  }

  @media (max-width: 380px) {
    left: 50px;
    top: 30px;
  }
`;
