import styled from "styled-components";

export const Container = styled.div``;

export const Text = styled.h1`
  font-family: Poppins;
  font-size: 94.85px;
  font-weight: 400;
  text-align: left;
  position: absolute;
  z-index: 999;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 60px;
  }

  @media (max-width: 1440px) {
    font-size: 50px;
  }

  @media (max-width: 1024px) {
    font-size: 64px;
    top: 500px;
  }

  @media (max-width: 820px) {
    font-size: 50px;
    top: 430px;
  }

  @media (max-width: 768px) {
    font-size: 45px;
  }

  @media (max-width: 431px) {
    top: 365px;
    left: 29px;
    font-size: 28.81px;
  }

  @media (max-width: 360px) {
    font-size: 25px;
    top: 310px;
  }

  @media (max-width: 376px) {
    font-size: 24px;
    top: 280px;
  }
`;

export const Background = styled.div`
  background-color: #f2f2f2;
  position: absolute;
  z-index: 998;
  margin-top: 4rem;
  width: 64%;
  height: 140px;
  border-radius: 100px;

  @media (min-width: 1440px) and (max-width: 1660px) {
    margin-top: 3rem;
    height: 80px;
    width: 48%;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    margin-top: 3rem;
    height: 80px;
    width: 48%;
  }

  @media (max-width: 1024px) {
    width: 768px;
    height: 96px;
    top: 485px;
    left: 85px;
  }

  @media (max-width: 820px) {
    width: 600px;
    height: 75px;
    top: 400px;
    left: 50px;
  }

  @media (max-width: 768px) {
    width: 580px;
    height: 65px;
    top: 400px;
    left: 50px;
  }

  @media (max-width: 431px) {
    width: 367px;
    height: 45.88px;
    top: 320px;
    left: -2px;
  }

  @media (max-width: 360px) {
    width: 325px;
    height: 35px;
    top: 265px;
  }

  @media (max-width: 376px) {
    width: 310px;
    height: 38px;
    top: 232px;
  }
`;

export const SecondBackground = styled.div`
  background-color: #f2f2f2;
  position: absolute;
  z-index: 998;
  margin-top: 4rem;
  width: 64%;
  height: 140px;
  border-radius: 100px;
  display: none;

  @media (max-width: 1024px) {
    width: 768px;
    height: 100px;
    top: 579px;
    left: -140px;
    display: inline;
  }

  @media (max-width: 820px) {
    width: 430px;
    height: 75px;
    top: 473px;
    left: 50px;
  }

  @media (max-width: 768px) {
    width: 430px;
    height: 68px;
    top: 464px;
    left: 50px;
  }

  @media (max-width: 431px) {
    width: 367px;
    height: 45.88px;
    top: 365px;
    left: -95px;
  }

  @media (max-width: 360px) {
    width: 325px;
    height: 40px;
    top: 298px;
  }

  @media (max-width: 376px) {
    width: 320px;
    height: 38px;
    top: 268px;
  }
`;
