import React, { useRef, useState, useEffect } from "react";
import * as C from "./App.styles";
import Header from "./components/header/Header";
import image from "./assets/images/image.png";
import image2 from "./assets/images/image_2.png";
import Slogan from "./components/slogan/Slogan";
import Description from "./components/description/Description";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";

function App() {
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const [displayedImage, setDisplayedImage] = useState(image);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 1300 && window.innerWidth <= 1660) {
      setDisplayedImage(image2);
    } else {
      setDisplayedImage(image);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <C.Container>
        <Header
          scrollToSection={scrollToSection}
          aboutRef={aboutRef}
          contactRef={contactRef}
        />
        <C.Center>
          <Slogan />
          <Description scrollToAbout={aboutRef} />
          <C.Image src={displayedImage} />
        </C.Center>
      </C.Container>
      <About ref={aboutRef} />
      <Contact ref={contactRef} />
    </>
  );
}

export default App;
