import React from "react";
import * as C from "./Header.styles";
import logo from "../../assets/images/logo.png";

const Header = ({ scrollToSection, aboutRef, contactRef }) => {
  return (
    <C.Container>
      <C.Logo src={logo} />
      <C.Menu>
        <C.Text onClick={() => scrollToSection(aboutRef)}>A EMPRESA</C.Text>
        <C.Text onClick={() => scrollToSection(contactRef)}>CONTATO</C.Text>
      </C.Menu>
    </C.Container>
  );
};

export default Header;
