import styled from "styled-components";

export const Container = styled.div`
  margin-block: 4rem;
  margin-inline: 6.2rem;

  @media (max-width: 431px) {
    margin: 2rem;
  }
`;

export const Title = styled.span`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 400;
  text-align: left;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 18px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 1024px) {
    font-size: 26.67px;
  }

  @media (max-width: 360px) and {
    font-size: 20px;
  }

  @media (max-width: 380px) {
    font-size: 20px;
  }
`;

export const Text = styled.a`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: black;
  text-decoration: none;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 16px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 15px;
  }

  @media (max-width: 380px) {
    font-size: 13px;
  }
`;

export const TextLocation = styled.a`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: right;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 16px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 431px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }
`;

export const Indent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;

  @media (max-width: 1024px) {
    width: 25px;
  }
`;

export const Image = styled.img`
  @media (min-width: 1440px) and (max-width: 1660px) {
    width: 220px;
    height: 146px;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 220px;
    height: 146px;
  }

  @media (max-width: 1024px) {
    width: 180px;
    height: 106px;
  }

  @media (max-width: 431px) {
    display: none;
  }
`;

export const Social = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 380px) {
    margin-top: 1rem;
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const All = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 431px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;
