import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 26rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: 1440px) and (max-width: 1660px) {
    margin-top: 16rem;
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    margin-top: 14.5rem;
  }
`;

export const Text = styled.span`
  font-family: Ubuntu;
  font-size: 32px;
  text-align: left;
  color: #000000;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 20px;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    margin-top: 16rem;
    font-size: 34px;
    position: absolute;
  }

  @media (max-width: 820px) {
    font-size: 25px;
    margin-top: 7.5rem;
  }

  @media (max-width: 431px) {
    display: none;
  }
`;

export const Bold = styled.b`
  color: #3be65a;
`;

export const TextPhone = styled.span`
  font-family: Ubuntu;
  font-size: 23.84px;
  text-align: left;
  color: #000000;
  position: absolute;
  top: 535px;
  display: none;

  @media (max-width: 431px) {
    display: inline;
  }

  @media (max-width: 415px) {
    font-size: 20px;
    top: 520px;
  }

  @media (max-width: 360px) {
    font-size: 16px;
    top: 440px;
  }

  @media (max-width: 380px) {
    font-size: 16px;
    top: 410px;
  }
`;

export const Button = styled.div`
  background-color: transparent;
  padding: 1rem;
  width: 25%;
  border: 2px solid #3be65a;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1440px) {
    padding: 0.5rem;
  }

  @media (max-width: 1024px) {
    margin-top: 32rem;
    position: absolute;
  }

  @media (max-width: 820px) {
    margin-top: 17.5rem;
    width: 30%;
  }

  @media (max-width: 431px) {
    width: 40%;
    margin-top: 16.5rem;
  }

  @media (max-width: 415px) {
    margin-top: 13rem;
  }

  @media (max-width: 360px) {
    margin-top: 6rem;
  }

  @media (max-width: 380px) {
    margin-top: 4rem;
    padding: 0.5rem;
  }
`;

export const ButtonTxt = styled.span`
  font-family: Poppins;
  font-size: 24.91px;
  font-weight: 500;
  line-height: 33.63px;
  text-align: left;
  color: #3be65a;
  cursor: pointer;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 18px;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 431px) {
    font-size: 15.57px;
  }
`;
