import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.7rem;

  @media (max-width: 431px) {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 200px;

  @media (min-width: 1440px) and (max-width: 1660px) {
    width: 150px;
  }

  @media (max-width: 1440px) {
    width: 150px;
    height: 35px;
  }

  @media (max-width: 431px) {
    display: none;
  }
`;

export const Text = styled.span`
  font-family: Poppins;
  font-size: 26.67px;
  font-weight: 400;
  text-align: left;
  color: #000000;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 1440px) and (max-width: 1660px) {
    font-size: 18px;
    display: inline;
  }

  @media (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 431px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;

  @media (max-width: 431px) {
    display: none;
  }
`;
