import React from "react";
import * as C from "./About.styles";

const About = React.forwardRef((props, ref) => {
  return (
    <C.Container ref={ref}>
      <C.Indent>
        <C.Text>
          A <C.Bold>Mercadados</C.Bold> é uma startup inovadora e dedicada a
          democratizar a inteligência de dados
          <br />e aumentar a eficiência em toda a cadeia do varejo.
          <br />
          <br />
          <br />
          Conectada a diversos distribuidores nacionais e indústrias globais,
          nossa principal entrega
          <br />
          de valor é impulsionar a rentabilidade de mercados e redes de médio e
          pequeno porte.
          <br />
          <br />
          <br />
          Entre as entregas da ferramenta Mercadados estão o fornecimento de
          informações práticas
          <br />
          para os compradores tomarem decisões de compras e realizarem
          negociações de forma
          <br />
          embasada. Análises de vendas simplificadas do mercado e da região,
          clareza quanto
          <br />
          aos custos e impostos, análises da composição de carrinhos
          possibilitando a otimização dos
          <br />
          preços, balanceando a margem e relevância para a atrair clientes de
          cada produto, elevando
          <br />a margem de contribuição total e relevância do mercado na
          região.
        </C.Text>
        <C.TabletText>
          A <C.Bold>Mercadados</C.Bold> é uma startup inovadora e<br />
          dedicada a democratizar a inteligência de
          <br />
          dados e aumentar a eficiência em toda a<br />
          cadeia do varejo.
          <br />
          <br />
          <br />
          Conectada a diversos distribuidores nacionais
          <br />
          e indústrias globais, nossa principal entrega de
          <br />
          valor é impulsionar a rentabilidade de
          <br />
          mercados e redes de médio e pequeno porte.
          <br />
          <br />
          <br />
          Entre as entregas da ferramenta <C.Bold>Mercadados</C.Bold>
          <br />
          estão o fornecimento de informações práticas
          <br />
          para os compradores tomarem decisões de
          <br />
          compras e realizarem a negociação dos
          <br />
          valores de forma embasada. Análises de
          <br />
          vendas simplificadas do mercado e da região,
          <br />
          além de clareza quanto aos custos e impostos,
          <br />
          possibilitando e otimizando a definição de
          <br />
          preços balanceando a margem e relevância
          <br />
          para atrair clientes de cada produto, elevando
          <br />a margem de contribuição total.
        </C.TabletText>
        <C.PhoneText>
          A <C.Bold>Mercadados</C.Bold> é uma startup<br/>
          inovadora e dedicada a<br/>
          democratizar a inteligência de<br/>
          dados e aumentar eficiência em<br/>
          toda a cadeia do varejo.
          <br/><br/><br/>
          Conectada a diversos<br/>
          distribuidores nacionais e<br/>
          indústrias globais, nossa principal<br/>
          entrega de valor é impulsionar a<br/>
          rentabilidade de mercados e redes<br/>
          de médio e pequeno porte.
          <br/><br/><br/>
          Entre as entregas da ferramenta<br/>
          <C.Bold>Mercadados</C.Bold> estão o fornecimento<br/>
          de informações práticas para os<br/>
          compradores tomarem decisões<br/>
          de compras e realizarem a<br/>
          negociação dos valores de forma<br/>
          embasada. Análises de vendas<br/>
          simplificadas do mercado e da<br/>
          região, além de clareza quanto<br/>
          aos custos e impostos,<br/>
          possibilitando e otimizando a<br/>
          definição de preços balanceando<br/>
          a margem e relevância para atrair<br/>
          clientes de cada produto, elevando<br/>
          a margem de contribuição total.
        </C.PhoneText>
      </C.Indent>
      <C.Background />
    </C.Container>
  );
});

export default About;
