import * as C from "./Slogan.styles";

const Slogan = () => {
  return (
    <C.Container>
      <C.Background />
      <C.SecondBackground />
      <C.Text>
        Acessível para crescer
        <br />
        junto com você
      </C.Text>
    </C.Container>
  );
};

export default Slogan;
